import * as React from 'react';
import clsx from 'clsx';

import styles from './BookingWidgetSectionHeader.module.css';

interface Props {
  text: string;
}

export const BookingWidgetSectionHeader = ({ text }: Props) => {
  return (
    <h3
      className={clsx(
        styles['pacific-rotate'],
        styles['txt-center'],
        styles['semi-big-text'],
        'base-marginBottom-16',
        'base-key__text'
      )}
    >
      {text}
    </h3>
  );
};

export default BookingWidgetSectionHeader;
